import { AnalyticsEvents as PUB, AllAnalyticsEvents as ALL } from '../../javascript/display/common/config/api.js';
import {
  findNGXIframe,
  isInternetExplorer,
  getScrollTop,
  getOffsetTop,
  getClientHeight
} from './dom.js';

export default function AnalyticsOutsideIframe(w, d, NGX) {
  const isIE = isInternetExplorer();

  var visibleTimeout = null;
  var integrationBuffer = [];

  // Gets called for the 'integration:bus:ready' event, to finish initialization
  function syncOutsideIntegrationEvents(event) {
    // Integrations will want to have accurate iframe ids
    pingAllFrames();

    // Signal that buffering has ceased
    if (!integrationBuffer) {
      return;
    }
    var buffer = integrationBuffer;
    integrationBuffer = false;

    buffer.forEach(function(raw) {
      var iframe = raw[0];
      var data = raw[1];
      sendIntegrationMessage(iframe, data.event, data.params);
    });
  }

  function pingAllFrames() {
    Array.prototype.forEach.call(document.querySelectorAll('iframe[id^=ngxFrame]'), function (iframe) {
      iframe.contentWindow.postMessage({
        id:     iframe.id,
        target: 'ping'
      }, '*');
    });
  }

  function buildCompatiblityAPI(window, NGX) {
    var callbacks = {
      'integration:bus:ready': [syncOutsideIntegrationEvents],
    };

    // The objective is to provide a stub that mimics NGX.Analytics.on(event: String, callback: function)
    var stubEventListener = function(eventSignature, callback) {
      if (['*'].concat(ALL).indexOf(eventSignature) === -1) {
        console.error('Legacy does not support "' + eventSignature + '", only:', PUB);
        return;
      }
      var events = (eventSignature === '*') ? ALL : [eventSignature];
      for (var idx=0; idx < events.length; idx++) {
        var event = events[idx];
        callbacks[event] = (callbacks[event] || []).concat([callback]);
      }
    };

    var eventHandlerCallback = function(raw) {
      if (callbacks[raw.event]) {
        callbacks[raw.event].forEach(function(cb) {
          cb(Object.assign({ id: raw.id }, raw.params), raw.event);
        });
      }
    };

    const compatibilityAPI = {
      api:       stubEventListener,
      callback:  eventHandlerCallback,
    };

    return compatibilityAPI;
  }

  function handleVisible() {
    clearTimeout(visibleTimeout);
    visibleTimeout = setTimeout(
      function(isIE) {
        const iframe = findNGXIframe();
        if (!iframe) {
          return;
        }
        const clientHeight = getClientHeight(),
          scrollTop = getScrollTop(),
          offsetTop = getOffsetTop(iframe);
        const top = offsetTop,
          bottom = offsetTop + iframe.height;

        /*
        --- scrollTop
          +-- offsetTop
          |
          +-- offsetTop + iframe.height

        --- scrollTop + clientHeight
        */
        if (
          (top > scrollTop && top < scrollTop + clientHeight) ||
          (bottom > scrollTop && bottom < scrollTop + clientHeight)
        ) {
          sendIntegrationMessage(iframe, PUB.APP_VIEWPORT);
          if (isIE) {
            window.detachEvent('scroll', handleVisible);
          } else {
            window.removeEventListener('scroll', handleVisible);
          }
        }
      },
      200,
      isIE
    );
  }

  /**
   * Sends a message encoded for ngx integration event bus
   *
   * @param {HTMLIFrameElement} iframe - All current sendMessageEvents already lookup the iframe (so we use it).
   * @param {String} eventAction
   * @param {Object} params
   */
  function sendIntegrationMessage(iframe, eventAction, params) {
    var event = {
      id:     (iframe) ? iframe.id : '',
      event:  eventAction,
      params: params || {},
      target: 'integration',
    };
    if (integrationBuffer !== false) {
      integrationBuffer.push([{ id: iframe.id }, event]);
      return;
    }
    // Listeners subscribed outside the iframe need to be notified as well as those inside the iframe. We don't
    // echo events from the outside back outside.
    if (!iframe) {
      return;
    }
    // compatibility.callback(event);
    var frame = document.getElementById(iframe.id);
    if (frame) {
      frame.contentWindow.postMessage(event, '*');
    } else {
      console.warn('Unable to find original iframe with id="' + iframe.id + '" for event:' + eventAction);
    }
  }

  function isValidIntegrationMessage(msg) {
    return typeof msg.data === 'object' && msg.data.target === 'integration';
  }

  function parseMessage(e) {
    if (isValidIntegrationMessage(e)) {
      compatibility.callback(e.data);
    }
  }

  // Listeners
  function enableListeners() {
    if (isIE) {
      window.attachEvent('onmessage', parseMessage);
      window.attachEvent('onscroll', handleVisible);
    } else {
      window.addEventListener('message', parseMessage, false);
      window.addEventListener('scroll', handleVisible, false);
    }
    // Handle case where it's immediately visible and user is unable to scroll
    handleVisible();
  }

  function disableListeners() {
    if (isIE) {
      window.detachEvent('onmessage', parseMessage);
      window.detachEvent('onscroll', handleVisible);
    } else {
      window.removeEventListener('message', parseMessage, false);
      window.removeEventListener('scroll', handleVisible, false);
    }
  }

  // Analytics events public API
  var compatibility = buildCompatiblityAPI(window, NGX);

  function init() {
    enableListeners();
  }
  function finish() {
    disableListeners();
  }

  var publicAPI = {
    finish: finish,
    init:   init,
    on:     compatibility.api
  };

  var keys = Object.keys(PUB);
  for (var i=0; i < keys.length; i++) {
    var key = keys[i];
    publicAPI[key] = PUB[key];
  }
  publicAPI.ALL_EVENTS = '*';

  return publicAPI;
}
