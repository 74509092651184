export const AnalyticsEvents = {
  ACTION_CONTENT_CLICK:            'action:content:click',
  ACTION_CTA:                      'action:cta',
  ACTION_GOAL:                     'action:goal',
  ACTION_NEXT:                     'action:next',
  ACTION_PREVIOUS:                 'action:previous',
  ACTION_SHARE_CAMPAIGN_FACEBOOK:  'action:share:campaign:facebook',
  ACTION_SHARE_CAMPAIGN_PINTEREST: 'action:share:campaign:pinterest',
  ACTION_SHARE_CAMPAIGN_TWITTER:   'action:share:campaign:twitter',
  ACTION_SHARE_CONTENT_FACEBOOK:   'action:share:content:facebook',
  ACTION_SHARE_CONTENT_PINTEREST:  'action:share:content:pinterest',
  ACTION_SHARE_CONTENT_TWITTER:    'action:share:content:twitter',
  ACTION_SHOWMORE:                 'action:showmore',
  APP_VIEWPORT:                    'app:viewport',
  FLOW_NEXT:                       'flow:next',
  FLOW_PREV:                       'flow:prev',
  FORM_COPPA_FAILED:               'form:coppa:failed',
  FORM_COPPA_PASSED:               'form:coppa:passed',
  FORM_ENTRY_ALREADYENTERED:       'form:entry:alreadyentered',
  FORM_ENTRY_FAILED:               'form:entry:failed',
  FORM_ENTRY_SUCCESS:              'form:entry:success',
  FORM_MODULE_INTERACTION:         'form:module:interaction',
  FORM_SUBMIT:                     'form:submit',
  INSIGHT_CLICKTHROUGH:            'insight:clickthrough',
  INSIGHT_FIRSTINTERACTION:        'insight:firstinteraction',
  NAVIGATE_PAGE:                   'navigate:page',
  NAVIGATE_PAGE_CONFIRMATION:      'navigate:page:confirmation',
  VOTE_FAILURE:                    'vote:failure',
  VOTE_SUCCESS:                    'vote:success',
};

export const AllAnalyticsEvents = [
  'action:content:click',
  'action:cta',
  'action:goal',
  'action:next',
  'action:previous',
  'action:share:campaign:facebook',
  'action:share:campaign:pinterest',
  'action:share:campaign:twitter',
  'action:share:content:facebook',
  'action:share:content:pinterest',
  'action:share:content:twitter',
  'action:showmore',
  'app:viewport',
  'flow:next',
  'flow:prev',
  'form:coppa:failed',
  'form:coppa:passed',
  'form:entry:alreadyentered',
  'form:entry:failed',
  'form:entry:success',
  'form:module:interaction',
  'form:submit',
  'insight:clickthrough',
  'insight:firstinteraction',
  'navigate:page',
  'navigate:page:confirmation',
  'vote:failure',
  'vote:success',
];

export const Events = {
  PAGE_READY:                      'page:ready',
  FORM_FIELD_BLUR:                 'form:field:blur',
  FORM_FIELD_FOCUS:                'form:field:focus',
  FORM_VALIDATION_FAILED:          'form:validation:failed',
  FORM_VALIDATION_PASSED:          'form:validation:passed',
  OVERLAY_DESTROYED:               'overlay:destroyed',
  OVERLAY_HIDDEN:                  'overlay:hidden',
  OVERLAY_READY:                   'overlay:ready',
};

export const AllEvents = [
  'page:ready',
  'form:field:blur',
  'form:field:focus',
  'form:validation:failed',
  'form:validation:passed',
  'overlay:destroyed',
  'overlay:hidden',
  'overlay:ready',
];

export const DeprecatedAnalyticsEvents = [
  'navigate:page:details',
  'form:rating:click',
];

export function isVersion(version) {
  return '' + version === '1';
};
