export function isInternetExplorer() {
  return !window.addEventListener;
}

export function findNGXIframe() {
  var _ngxIframes = [],
    iframes = document.getElementsByTagName('iframe');

  for (var i = 0; i < iframes.length; i++) {
    if (iframes[i].id.indexOf('ngxFrame') === 0) {
      _ngxIframes.push(iframes[i]);
    }
  }
  return _ngxIframes[0];
}

export function getClientHeight() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};

export function getScrollTop()  {
  if (typeof window.pageYOffset !== 'undefined') {
    return window.pageYOffset;
  }
  return (document.documentElement || document.body.parentNode || document.body).scrollTop;
};

export function getOffsetTop(el) {
  if (!el) {
    return 0;
  }
  var currTop = 0;
  if (el.offsetParent) {
    do {
      currTop += el.offsetTop;
    } while ((el = el.offsetParent));
    return currTop;
  }
};
