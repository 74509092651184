import Analytics from './Analytics.js';

var NGX = NGX || {};

// IE11: Polyfills
(function() {
  // Custom event polyfill.
  if (typeof window.CustomEvent === 'function') return false;
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;

  // Object.assign polyfill.
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
      value: function assign(target, varArgs) {
        // .length of function is 2
        'use strict';
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource !== null && nextSource !== undefined) {
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable:     true,
      configurable: true,
    });
  }
})();

NGX.Embed = (function(window, document) {
  function integrationHello(window, id) {
    if (typeof window === 'undefined' || typeof window.CustomEvent !== 'function') {
      return;
    }
    window.dispatchEvent(new CustomEvent('ngx:integration:response', { detail: { id: id, NGX: NGX } }));
  }

  function isVersion(version) {
    return '' + version === '1';
  }

  function buildCompatiblityAPI(window, NGX, analytics, id) {
    id = id || [0];

    var callbacks = {};

    var stubEventListener = function(target, eventSignature, callback) {
      switch (target) {
        case 'event:listen':
          callbacks[eventSignature] = (callback[eventSignature] || []).concat(callback);
          break;
        case 'analytics:listen':
          analytics.on(eventSignature, callback);
          break;
        default:
          console.error('Legacy does not support "' + target + '", only "event:listen" or "analytics:listen".');
      }
    };

    var eventHandlerCallback = function(raw) {
      if (callbacks[raw.event]) {
        callbacks[raw.event].forEach(function(cb) {
          const args = { id: raw.id };
          if (raw.params) {
            args.params = raw.params;
          }
          cb(args);
        });
      }
      // Support NGX.api('event:listen', '*', ...) until we have a list of events we want to limit our users
      // to.
      if (callbacks['*']) {
        callbacks['*'].forEach(function(cb) {
          // NOTE: params will always be stripped off events sent outside the iframe, because of the legacy
          // payloads some events deliver. At the time of writing the API we had no control over this, and
          // it was simpler and safer to drop the params.
          cb({ id: raw.id }, raw.event);
        });
      }
    };

    function init() {
      // Trigger a notification if any integration is already listening, and listen if one loads later
      window.addEventListener('ngx:integration', function() {
        integrationHello(window, id);
      });

      integrationHello(window, id);
    }

    const compatibilityAPI = {
      api:       stubEventListener,
      isVersion: isVersion,
      callback:  eventHandlerCallback,
      init:      init,
    };

    return compatibilityAPI;
  }

  // Store callback function refs keyed by message id.
  var callbackQueue = {};

  // Reference to internal scroll timeout.
  var scrollTimeout = null;
  var visibleTimeout = null;

  // dom utils for iframe sizing
  var getOffsetTop = function(el) {
    var currTop = 0;
    if (el.offsetParent) {
      do {
        currTop += el.offsetTop;
      } while ((el = el.offsetParent));
      return currTop;
    }
  };

  var getClientHeight = function() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  };

  var getScrollTop = function() {
    return window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  };

  // Public API methods
  var _actions = {
    // Get page info of parent frame
    getpageinfo: function(target, msg) {
      var _result = {
        action:     'returnpageinfo',
        callbackId: msg.msgId,
        payload:    {
          clientHeight: getClientHeight(),
          scrollTop:    getScrollTop(),
          offsetTop:    getOffsetTop(target),
          innerHeight:  window.innerHeight,
          url:          window.location.href,
        },
      };
      return '_NGX_' + JSON.stringify(_result);
    },

    broadcast: function(target, msg) {
      var _result = {
        action:     'returninfo',
        callbackId: msg.msgId,
        payload:    {},
      };
      return '_NGX_' + JSON.stringify(_result);
    },

    scrollto: function(target, msg) {
      window.scrollTo(msg.payload.left || 0, msg.payload.top || 0);
    },

    // Resize the frame
    resize: function(target, msg) {
      if (msg.payload.hasOwnProperty('height')) target.height = msg.payload.height;
    },
  };

  var enableListeners = function(apiCompatibilityCallback, analytics) {
      var parseMessage = receiveMessage.bind(null, apiCompatibilityCallback);
      if (window.addEventListener) {
        window.addEventListener('message', parseMessage, false);
        window.addEventListener('click', parentClick, false);
      } else {
        window.attachEvent('onmessage', parseMessage);
        window.attachEvent('onclick', parentClick);
      }

      analytics.init();

      monitorScroll();
    },
    monitorScroll = function() {
      handleVisible = handleVisible.bind(null, !window.addEventListener);
      if (window.addEventListener) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleVisible);
      } else {
        window.attachEvent('onscroll', handleScroll);
      }
    },
    disableListeners = function(apiCompatibilityCallback, analytics) {
      var parseMessage = receiveMessage.bind(null, apiCompatibilityCallback);
      if (window.removeEventListener) {
        window.removeEventListener('message', parseMessage, false);
        window.removeEventListener('click', parentClick, false);
        window.removeEventListener('scroll', handleScroll, false);
        window.removeEventListener('scroll', handleVisible, false);
      } else {
        window.detachEvent('onmessage', parseMessage);
        window.detachEvent('onclick', parentClick);
        window.detachEvent('onscroll', handleScroll);
        window.detachEvent('onscroll', handleVisible);
      }
      analytics.finish();
    },
    handleScroll = function(e) {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function() {
        var iframe = findNGXIframe();
        var message = {
          id:      iframe.id,
          action:  'triggerEvent',
          payload: {
            event:        'parent:event:scroll',
            clientHeight: getClientHeight(),
            scrollTop:    getScrollTop(),
            offsetTop:    getOffsetTop(iframe),
          },
        };
        sendMessage(message);
      }, 200);
    },
    handleVisible = function(isIE, e) {
      clearTimeout(visibleTimeout);
      visibleTimeout = setTimeout(
        function(isIE) {
          var iframe = findNGXIframe(),
            clientHeight = getClientHeight(),
            scrollTop = getScrollTop(),
            offsetTop = getOffsetTop(iframe);
          var top = offsetTop,
            bottom = offsetTop + iframe.height;

          /*
          --- scrollTop
            +-- offsetTop
            |
            +-- offsetTop + iframe.height

          --- scrollTop + clientHeight
          */
          if (
            (top > scrollTop && top < scrollTop + clientHeight) ||
            (bottom > scrollTop && bottom < scrollTop + clientHeight)
          ) {
            sendMessage({
              id:      iframe.id,
              action:  'triggerEvent',
              payload: { event: 'app:viewport' },
            });
            if (isIE) {
              window.detachEvent('scroll', handleVisible);
            } else {
              window.removeEventListener('scroll', handleVisible);
            }
          }
        },
        200,
        isIE
      );
    },
    sendMessage = function(msgObj, callback) {
      if ('object' === typeof msgObj) {
        msgObj.msgId = Date.now();

        if ('function' === typeof callback) {
          // Store the callback for later use
          callbackQueue[msgObj.msgId] = callback;
        }

        var _frame = document.getElementById(msgObj.id);
        return _frame.contentWindow.postMessage('_NGX_' + JSON.stringify(msgObj), '*');
      }
    },
    registerEventHandler = function(event, fn) {
      if ('function' === typeof fn) {
        NGX.Embed.eventHandlers[event || '*'] = fn;
      }
    },
    // Get the customer id from the iframe api, return it to the user-supplied callback
    getCustomerID = function(callback) {
      var callbackWrapper = function(payload) {
        // Extract the user object from the returned payload.
        var customerid = payload.customerid;
        // Execute the supplied callback, passing just the customerid.
        callback.call(this, customerid);
      };
      // Send the message to the iframe embed listener
      var iframe = findNGXIframe();
      sendMessage(
        {
          id:      iframe.id,
          action:  'getcustomerid',
          payload: {},
        },
        callbackWrapper
      );
    },
    // Send the customer id to the iframe embed listener
    setCustomerID = function(id, type) {
      var iframe = findNGXIframe();
      sendMessage({
        id:      iframe.id,
        action:  'setcustomerid',
        payload: { customerid: id, customeridtype: type },
      });
    },

    // Send the customer's privacy / cookie consent settings to the iframe embed listener
    setPrivacyConsents = function(consentSettings) {
      // Make sure we're getting expected values.
      if (typeof consentSettings !== 'object') {
        console.error('Error: setPrivacyConsents expects consent settings to be an object in the following format { functional: true|false, marketing: true|false, analytics: true|false }.');
        return;
      }
      const sanitizedSettings = whitelistFilter(consentSettings, consentWhitelist);
      const iframe = findNGXIframe();

      sendMessage({
        id:      iframe.id,
        action:  'setprivacyconsents',
        payload: { consentSettings: sanitizedSettings },
      });
    };

  // Internal Methods
  const consentWhitelist = ['functional', 'marketing', 'analytics'];
  const whitelistFilter = (obj, whitelist) => Object.entries(obj)
    .filter(([key, value]) => whitelist.includes(key) && typeof value === 'boolean')
    .reduce((obj, [key, value]) => (obj[key] = value, obj), {});

  function isValidMessage(msg) {
    return typeof msg.data === 'string' && msg.data.indexOf('_NGX_') === 0;
  }

  /**
   * messages a click event from parent to iframe
   * @param e
   */
  function parentClick(e) {
    sendMessage({
      id:      findNGXIframe().id,
      action:  'triggerEvent',
      payload: {
        event: 'parent:event:click',
      },
    });
  }

  function receiveMessage(apiCompatibilityCallback, e) {
    if (isValidMessage(e)) {
      var msgObj;
      try {
        msgObj = JSON.parse(e.data.substring(5));
      } catch (e) {
        console.error('Unable to deserialise message JSON', e);
        return;
      }

      if (msgObj && msgObj.hasOwnProperty('callbackId')) {
        var cbID = msgObj.callbackId;

        if (callbackQueue.hasOwnProperty(cbID) && 'function' === typeof callbackQueue[cbID]) {
          try {
            callbackQueue[cbID].call(this, msgObj.payload);
            delete callbackQueue[cbID];
          } catch (e) {
            console.error('Failed while running message.callbackId', e);
          }
        }
      }

      var result = messageAction(apiCompatibilityCallback, msgObj);
      if (result) e.source.postMessage(result, '*');
    }
  }

  function messageAction(apiCompatibilityCallback, msg) {
    var getIframeFromMessage = function(msg) {
      var _msgId = msg.id,
        _compoundId,
        _compoundIdParts,
        _guidId,
        _intId;
      var guidFrame, intFrame;

      if (!_msgId) return false;

      _compoundId = _msgId.substring(8); // ngxFrame == 8 chars
      _compoundIdParts = _compoundId.split(':');
      _guidId = _compoundIdParts[0];
      _intId = _compoundIdParts[1];

      if ((guidFrame = document.getElementById('ngxFrame' + _guidId))) {
        return guidFrame;
      } else if ((intFrame = document.getElementById('ngxFrame' + _intId))) {
        return intFrame;
      }

      return false;
    };

    var theFrame = getIframeFromMessage(msg) || findNGXIframe();

    if (msg.action === 'event') {
      // The NGX.api events are all triggered from here:
      apiCompatibilityCallback(msg);

      var msgObj = {};

      if (NGX.Embed.eventHandlers.hasOwnProperty(msg.event.toLowerCase())) {
        msgObj = NGX.Embed.eventHandlers[msg.event.toLowerCase()].call(this, msg.event, msg.payload, msg.msgId) || {};
      }

      msgObj.callbackId = msg.msgId;
      msgObj.id = msg.id;
      try {
        var serializedMsg = '_NGX_' + JSON.stringify(msgObj);
        theFrame.contentWindow.postMessage(serializedMsg, '*');
      } catch (e) {
        console.error('Failed to serialize and send message to iframe\'s parent context', e);
      }
    } else {
      if (_actions.hasOwnProperty(msg.action.toLowerCase())) {
        try {
          return _actions[msg.action.toLowerCase()].call(this, theFrame, msg);
        } catch (e) {
          console.error('Message initiated Action "' + msg.action.toLowerCase() + '" failed', e);
          return;
        }
      }
    }
  }

  function findNGXIframe() {
    var _ngxIframes = [],
      iframes = document.getElementsByTagName('iframe');

    for (var i = 0; i < iframes.length; i++) {
      if (iframes[i].id.indexOf('ngxFrame') === 0) {
        _ngxIframes.push(iframes[i]);
      }
    }
    return _ngxIframes[0];
  }

  // Auth related.
  var auth = (function() {
    // Allow parent to register handlers for when we do the authentication.
    var registerLoginSuccessHandler = function(fn) {
        registerEventHandler('auth:login:success', fn);
      },
      registerLoginFailHandler = function(fn) {
        registerEventHandler('auth:login:fail', fn);
      },
      registerIdentifySuccessHandler = function(fn) {
        registerEventHandler('auth:identify:success', fn);
      },
      registerIdentifyFailHandler = function(fn) {
        registerEventHandler('auth:identify:fail', fn);
      },
      // Allow parent to notify us when they do the authentication.
      loginUser = function(user, callbackSuccess, callbackFail, frameId) {
        // Wrap supplied callbacks.
        var callbackWrapper = function(payload) {
          // Extract the success property from the returned payload.
          var success = payload.success;

          // Execute the supplied callback, passing appropriate args.
          if (success) {
            if (typeof callbackSuccess === 'function') {
              var user = payload.user;
              callbackSuccess.call(this, user);
            }
          } else {
            if (typeof callbackFail === 'function') {
              var error = payload.error;
              callbackFail.call(this, error);
            }
          }
        };

        // Send message back to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'event',
          event:   'auth:login:success',
          payload: { user: user },
        };
        sendMessage(message, callbackWrapper);
      },
      loginUserFail = function(error, frameId) {
        // Send message back to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'event',
          event:   'auth:login:fail',
          payload: { error: error },
        };
        sendMessage(message);
      },
      loginRequired = function(frameId) {
        // Send message back to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'event',
          event:   'auth:login:required',
          payload: {},
        };
        sendMessage(message);
      },
      // Allow parent to get user object.
      getUser = function(callback, frameId) {
        // Wrap supplied callback.
        var callbackWrapper = function(payload) {
          // Extract the user object from the returned payload.
          var user = payload.user;

          // Execute the supplied callback, passing just the user.
          callback.call(this, user);
        };

        // Send message to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'getuser',
          payload: {},
        };
        sendMessage(message, callbackWrapper);
      },
      getConfig = function(callback, frameId) {
        // Wrap supplied callback.
        var callbackWrapper = function(payload) {
          // Extract the config object from the returned payload.
          var config = payload.config;

          // Execute the supplied callback, passing just the config.
          callback.call(this, config);
        };
        // Send message to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'getauthconfig',
          payload: {},
        };
        sendMessage(message, callbackWrapper);
      },
      enableCustom = function(config, callback, frameId) {
        // Wrap supplied callback.
        var callbackWrapper = function(payload) {
          // Extract the config object from the returned payload.
          var config = payload.config;

          // Execute the supplied callback, passing just the config.
          callback.call(this, config);
        };
        // Send message to iframe.
        var message = {
          id:      frameId || findNGXIframe().id,
          action:  'enablecustomauth',
          payload: { config: config },
        };
        sendMessage(message, callbackWrapper);
      };

    return {
      registerLoginSuccessHandler:    registerLoginSuccessHandler,
      registerLoginFailHandler:       registerLoginFailHandler,
      registerIdentifySuccessHandler: registerIdentifySuccessHandler,
      registerIdentifyFailHandler:    registerIdentifyFailHandler,
      loginUser:                      loginUser,
      loginUserFail:                  loginUserFail,
      loginRequired:                  loginRequired,
      getUser:                        getUser,
      getConfig:                      getConfig,
      enableCustom:                   enableCustom,
    };
  })();

  function init() {
    NGX.Embed.listen();
  }

  var analytics = Analytics(window, document, NGX);

  var compatibility = buildCompatiblityAPI(window, NGX, analytics);

  // API compatibility with MX integration
  NGX.api = compatibility.api;
  NGX.isVersion = compatibility.isVersion;

  return {
    eventHandlers:        {},
    init:                 init,
    initIntegrations:     compatibility.init,
    listen:               enableListeners.bind(null, compatibility.callback, analytics),
    ignore:               disableListeners.bind(null, compatibility.callback, analytics),
    actions:              _actions,
    sendMessage:          sendMessage,
    registerEventHandler: registerEventHandler,
    getCustomerID:        getCustomerID,
    setCustomerID:        setCustomerID,
    setPrivacyConsents:   setPrivacyConsents,

    // Auth related
    registerLoginSuccessHandler:    auth.registerLoginSuccessHandler,
    registerLoginFailHandler:       auth.registerLoginFailHandler,
    registerIdentifySuccessHandler: auth.registerIdentifySuccessHandler,
    registerIdentifyFailHandler:    auth.registerIdentifyFailHandler,
    loginUser:                      auth.loginUser,
    loginUserFail:                  auth.loginUserFail,
    loginRequired:                  auth.loginRequired,
    getUser:                        auth.getUser,
    getAuthConfig:                  auth.getConfig,
    enableCustomAuth:               auth.enableCustom,
  };
})(window, document);

NGX.Embed.init();

// We have to use this hack until webpack is upgraded to a version that supports: `libraryExport: 'default'`.
window.NGX = NGX;

NGX.Embed.initIntegrations();
